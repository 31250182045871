<template>
  <SeeYouSoon />
</template>

<script>
import SeeYouSoon from "../components/SeeYouSoon";

export default {
  name: "Vertical",
  components: {
    SeeYouSoon
  }
}
</script>

<style scoped>

</style>
